import { Component, OnInit, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ApiCommunicationService } from 'src/app/_shared/services/_services';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AuthorizationResolver implements Resolve<any> {
  constructor(private api: ApiCommunicationService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const guid = route.paramMap.get('guid');
    return this.api.queryAuthorizationGuid(guid).pipe(
      map(response => response),
      catchError(ex => {
        return of({ guid: null, error: ex.error, errorCode: ex.status });
      })
    );
  }
}

@Component({
  selector: 'app-authorizatation',
  templateUrl: './authorizatation.component.html',
  styleUrls: ['./authorizatation.component.scss']
})
export class AuthorizatationComponent {
  fullName: string;
  guid: string;
  type: string;
  email: string;
  hideConfirm = false;
  error: string;

  constructor(private api: ApiCommunicationService, activatedRoute: ActivatedRoute) {
    const { guid, fullName, type, email, error, errorCode } = activatedRoute.snapshot.data['auth'];
    this.fullName = fullName;
    this.guid = guid;
    this.type = type;
    this.email = email;
    if (this.guid == null) {
      this.error = error;
    }
  }

  onSubmit() {
    this.api.confirmAuthorizationGuid(this.guid).subscribe(_ => {
      window.alert('User confirmed. Thank you.');
      this.hideConfirm = true;
    }, ex => window.alert(ex.error));
  }
}
