import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScohrpaginationComponent } from './scohrpagination/scohrpagination.component';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './banner/banner.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { NavMenusComponent } from './nav-menu/nav-menu.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ScohrpaginationComponent,
    FooterComponent,
    BannerComponent,
    ButtonGroupComponent,
    NavMenusComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    ScohrpaginationComponent,
    FooterComponent,
    BannerComponent,
    ButtonGroupComponent,
    NavMenusComponent
  ]
})
export class SharedComponentsModule { }
