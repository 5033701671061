import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { SharedComponentsModule } from '../_shared/components/shared-components.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthorizatationComponent } from './authorizatation/authorizatation.component';
import { ForgotModalComponent } from './forgot-password/forgot-modal/forgot-modal.component';

@NgModule({
  declarations: [
    SignupComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AuthorizatationComponent,
    ForgotModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    SharedComponentsModule
  ],
  exports: [
    LoginComponent,
    SignupComponent
  ]
})
export class PublicModule { }
