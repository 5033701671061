import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_shared/services/_services';
import { User } from '../login/login.component';
import { SCOHRValidators } from 'src/app/_shared/components/validators/scohr-validators';
import { ValidatorConstants} from 'src/app/_shared/components/validators/validator.constants';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  reset: any = {};
  user: User = new User();
  isValidGuid = false;
  guid = '';
  error = '';
  passwordRequirements : string;
  visible: boolean = true;

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.passwordRequirements = ValidatorConstants.PasswordRequirements;
    //this.reset.Password = ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')]]
    //this.reset.RepeatPassword = ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')]]

    this.activeRoute.paramMap.subscribe(params => {
      if (params.has('guid')) {
        this.guid = params.get('guid');
        this.authService.validPasswordResetRequest(this.guid)
          .then(_ => { this.isValidGuid = true; console.log(this.isValidGuid); })
          .catch(_ => this.error = 'Key is invalid or has expired');
      } else {
        this.error = 'Key is missing.';
      }
    });
  }

  toggleShowPassword() {
    if (this.reset.Password == this.visible) {
      this.reset.Password = !this.visible;
    }
    else {
      this.reset.Password = this.visible;
    }
   // this.visible = !this.visible;
  }

  toggleShowRepeatPassword() {
    if (this.reset.RepeatPassword == this.visible) {
      this.reset.RepeatPassword = !this.visible;
    }
    else {
      this.reset.RepeatPassword = this.visible;
    }
   // this.visible = !this.visible;
  }

  onSubmit() {
    this.error = '';
    //if (this.reset.Password.length < 8) {
    //  window.alert('Password Policy:\n At least 8 characters\n1 Uppercase\n 1 Lowercase\n 1 Number\n Inclusion of at least one special character, eg. ! @ # $ % ^ & * ? _ ~ - ( )\n No common words\n No username\n No spaces');
    //}

    if (this.reset.Password !== this.reset.RepeatPassword) {
      this.error = 'Passwords do not match';
    } else {
      this.user.Password = this.reset.Password;
      
    this.authService.passwordReset(this.guid, this.user)
        .then(_ => {
          window.alert('Password reset successfuly');
          this._router.navigate(['home/overview']);
        })
        .catch((e) => {
          window.alert(e.error);
        });
    }
  }

}
