import { Component, Input, Output, EventEmitter, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-scohrpagination',
  templateUrl: './scohrpagination.component.html',
  styleUrls: ['./scohrpagination.component.scss']
})
export class ScohrpaginationComponent implements AfterContentChecked {
  @Input() collectionSize: number;
  @Input() rows: number = 50;
  @Input() page: number;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output() maxChange: EventEmitter<any> = new EventEmitter();
  indexStart: number = 1;
  indexEnd: number;
  totalRows: number;

  constructor() { }

  ngAfterContentChecked(): void {
    this.totalRows = this.collectionSize > 0 ? Math.ceil(this.collectionSize / this.rows) : 1;
    this.indexStart = 1;// (this.page - 1) * this.rows + 1;
    const indexEndTest = this.indexStart + this.rows - 1;
    this.indexEnd = indexEndTest > this.collectionSize ? this.collectionSize : indexEndTest;
  }

  public onChange(page: number) {
    if (page < 1) {
      page = 1;
    } else if (page > this.totalRows) {
      page = this.totalRows;
    }
    this.pageChange.next(page);
  }
  public rowsChange(rows: number) {
    if (rows < 1) {
      rows = 1;
    }
    this.maxChange.next(rows);
  }
  public toLast() {
    this.page = this.totalRows;
    this.pageChange.next(this.page);
  }
  public toFirst() {
    this.page = 1;
    this.pageChange.next(this.page);
  }
  public toPrevious() {
    if (this.page > 1) {
      this.page -= 1;
      this.pageChange.next(this.page);
    }
  }
  public toNext() {
    this.page += 1;
    this.pageChange.next(this.page);
  }

}
