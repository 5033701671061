import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { AuthGuard, FiscalAuthGuard } from './_shared/services/_services';
import { SignupComponent } from './public/signup/signup.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { AuthorizatationComponent, AuthorizationResolver } from './public/authorizatation/authorizatation.component';

const routes: Routes = [
  { path: '', redirectTo: '/home/overview', pathMatch: 'full' },
  { path: 'home/:page', component: HomeComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgotPassword/:guid', component: ForgotPasswordComponent },
  { path: 'createPassword/:guid', component: ForgotPasswordComponent },
  { path: 'authorization/:guid', component: AuthorizatationComponent,
    resolve: {
      auth: AuthorizationResolver
    }},
  { path: 'admin', loadChildren: () => import('./admin/admin.module')
    .then(m => m.AdminModule), canLoad: [AuthGuard], canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [AuthGuard, FiscalAuthGuard, AuthorizationResolver]
})
export class AppRoutingModule { }
