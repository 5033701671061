import { Component, OnInit } from '@angular/core';
import { AuthenticationService, AuthGuard, ApiCommunicationService } from '../../services/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HelpDeskSitePermissions } from 'src/app/_shared/models/scohr/user.models';

@Component({
  selector: 'app-nav-menus',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenusComponent implements OnInit {
  isExpanded = false;
  isLoggedIn = false;
  isHelpDeskSession: boolean;
  typeId = 0;
  userId = 0;
  sitePages: HelpDeskSitePermissions[] = [];
  public username = '';
  response: any;

  protected helper = new JwtHelperService();
  constructor(private guard: AuthGuard,
    private auth: AuthenticationService,
    private api: ApiCommunicationService, 
    private router: Router  ) {
    this.isLoggedIn = guard.isAuthenticated();

    auth.scohrUserToken.subscribe((obj: any) => {
      this.isLoggedIn = guard.isAuthenticated();
      this.username = obj.user != null ? obj.user.username : '';
    });

    if (this.isLoggedIn) {

      this.auth.scohrUserToken.subscribe((obj: any) => {
        if (obj.user != null) {
          this.userId = obj.user.userId;
        } else {
          this.typeId = 0;
        }

      });
    }
  }

  async ngOnInit() {
    //check for active hd session
    let result = this.api.checkActiveHelpDeskSession(this.userId).subscribe(x => {
      console.log(x);
      if (x > 0) {
        this.isHelpDeskSession = true;
      } else {
        this.isHelpDeskSession = false;
      }
      //console.log(this.userId);
      //console.log(this.isHelpDeskSession);
      //console.log(this.helper.decodeToken(this.auth.getToken()))
      //alert(this.username.toLowerCase().indexOf('state') !== -1);
      let sitePermissions = this.api.getHelpDeskSessionSitePermission(this.userId).subscribe((data: HelpDeskSitePermissions[]) => {
        this.response = data;
        //console.log(this.response);
        this.response.forEach(element => {
          this.sitePages.push({
            id: element.id,
            permission: element.permission
          });
        });
      })

      });
    }

   returnToHelpDesk() {
    //stop active hd session
    //call api to stop hd session and get current admin IUser
    console.log('admin' && this.userId);
    let result = this.api.stopHelpDeskSession(this.userId).subscribe(x => {
      console.log('end session for as hd user');
      var user: any = {
        userId: x.id,
        username: x.username,
        typeId: x.typeID,
        email: x.email
      }
      this.auth.saveUser(user);
      //route to help desk page
      this.router.navigateByUrl('/admin/main')
        .then(() => {
          window.location.reload();
        });
    });

  }


  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public checkAuth(page: string): boolean {
    //console.log(this.typeId);
    if (this.isHelpDeskSession) {
      return this.isLoggedIn && this.sitePages.some(x => x.permission == page);
    }
    else {
        return this.isLoggedIn && this.guard.isAuthorized(page);
        //return this.isLoggedIn && this.sitePages.some(x => x.permission == page);
      }
  }

  public checkNotLocked(): boolean {
    return this.isLoggedIn && !this.guard.isFiscalYearLocked();
  }

  logout() {
    //if (this.isHelpDeskSession) {
    //  this.returnToHelpDesk();
    //  this.isHelpDeskSession = false;
    //  this.auth.logout();
    //}
    this.returnToHelpDesk();
    this.isHelpDeskSession = false;
    localStorage.clear();
    this.auth.logout();
  }
}
