import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

export class SharedService {
  protected _http: HttpClient;
  protected _router: Router;

  constructor(http: HttpClient, router: Router) {
    this._http = http;
    this._router = router;
  }
}
export function hasSpecialChars(str: string): boolean {
  const pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); // unacceptable chars
  return pattern.test(str);
}
export function isInvalidEmail(email: string): boolean {
  const pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  return !pattern.test(String(email).toLowerCase());
}
export function isNullOrEmpty(str: string): boolean {
  return (str == null || str.trim() === '');
}
