import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGuard } from 'src/app/_shared/services/_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  sp = 'overview';
  isLoggedIn = false;
  constructor(
    private activeRoute: ActivatedRoute,
    private auth: AuthGuard,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoggedIn = this.auth.isAuthenticated();
    this.activeRoute.paramMap.subscribe(params => {
      if (params.has('page')) {
        this.sp = params.get('page');
      }
    });
  }
}
