import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import {
  DistrictSearchResult,
  District,
  UserSearchResult,
  UserType,
  UsersMeta,
  Filter,
  QuickForm,
  SchoolSearchResult,
} from '../models/_models';

@Injectable({
  providedIn: 'root'
})
export class ApiCommunicationService extends SharedService {

  constructor(http: HttpClient, router: Router) {
    super(http, router);
  }

  setFiscalYearId(id: number) {
    localStorage.setItem('fiscal_year_id', id.toString());
  }

  getFiscalYearId(): number {
    return +localStorage.getItem('fiscal_year_id');
  }

  getFiscalYearsList() {
    return this._http.get<any>(`admin/fiscalyears`);
  }

  getFiscalYear(selectedYearId: number) {
    return this._http.get<any>(`admin/fiscalyear/${selectedYearId}`);
  }

  getAdminIndex(fiscalYear: number) {
    return this._http.get<any>(`admin/index/${fiscalYear}`);
  }

  getUserToVerify() {
    return this._http.get<any>('users/self');
  }

  queryAuthorizationGuid(guid: string) {
    return this._http.get<any>(`users/authorize/${guid}`);
  }

  confirmAuthorizationGuid(guid: string) {
    return this._http.post<any>(`users/authorize`, [guid]);
  }

  getDistrictList(filter: Filter) {
    return this._http.post<DistrictSearchResult[]>(
      `district/list/search`, filter
    );
  }

  getDistrictWithId(id: number) {
    return this._http.get<District>(`district/${id}`);
  }

  saveDistrict(form: District, id: number) {
    return this._http.post<any>(`district/save/${id}`, form);
  }

  changeDistrictParticipation(id: number) {
    return this._http.get<any>(`district/participation/${id}`);
  }

  checkDistrict(id: number) {
    return this._http.get<any>(`district/check/${id}`);
  }

  getUserList(filter: Filter) {
    return this._http.post<UserSearchResult[]>(
      `users/list/search`, filter
    );
  }

  getUserWithId(id: number) {
    return this._http.get<any>(`users/${id}`);
  }

  saveUser(form: any) {
    return this._http.post<any>(
      `users/${form.id}`, form
    );
  }

  verifyUser(form: any) {
    return this._http.post<any>(
      `users/self`, form
    );
  }

  deleteUser(id: number) {
    return this._http.delete<any>(
      `users/${id}`
    );
  }

  activateUserById(id: number) {
    return this._http.post<any>(
      `users/authorize/school/${id}`, [id]
    );
  }

  startHelpDeskSession(hdUserId: number) {
    return this._http.post<any>(`admin/helpdesk/${hdUserId}`, {});
  }

  checkActiveHelpDeskSession(userId: number){
    return this._http.get<any>(`admin/helpdesk/${userId}`);
  }

  stopHelpDeskSession(userId: number) {
    return this._http.delete<any>(`admin/helpdesk/${userId}`);
  }

  getHelpDeskSessionSitePermission(userId: number) {
    return this._http.get<any>(`admin/helpdesk/sitePermission/${userId}`);
  }

  saveSignup(form: any) {
    return this._http.post<any>(
      `users/signup`, form
    );
  }

  saveSignupNew(form: any) {
    return this._http.post<any>(
      `users/signupNew`, form
    );
  }

  getUserTypes() {
    return this._http.get<UserType[]>(`users/types`);
  }

  getUserMeta() {
    return this._http.get<UsersMeta>(`users/meta`);
  }

  getAnnouncementText() {
    return this._http.get<any>(`admin/announcement`);
  }

  saveAnnouncement(text: any) {
    return this._http.post<any>(`admin/announcement`, text);
  }

  getSignupMeta() {
    return this._http.get<any>(`users/signup/meta`);
  }

  // Data Input End points
  getDataInputList(fiscalYearId: number, filter: Filter) {
    return this._http.post<any>(
      `data/list/search/${fiscalYearId}`, filter
    );
  }
  getDataInputWithYearIdSchoolId(fiscalYearId: number, schoolId: number, filter: Filter) {
    return this._http.post<any>(
      `data/list/${schoolId}/${fiscalYearId}`, filter
    );
  }
  getDataInputWithFormId(filter: Filter, fiscalYearId: number, formId: number) {
    return this._http.post<any>(
      `data/error/${formId}/${fiscalYearId}`, filter
    );
  }
  saveDataInputForm(schoolId: number, form: QuickForm) {
    return this._http.post<any>(
      `data/save/${schoolId}`, form
    );
  }
  saveDataErrorForm(formId: number, form: any) {
    return this._http.post<any>(
      `data/error/save/${formId}`, form
    );
  }
  uploadDataInputFile(event: any, schoolId: number) {
    const fileList: FileList = event.target.form.myFile.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      return this._http.post<any>(`data/upload/${schoolId}`, formData);
    }
  }
  getDataFixUpload(fiscalYearId: number, filter: Filter) {
    return this._http.post<any>(
      `data/error/list/search/${fiscalYearId}`, filter
    );
  }
  deleteDataFix(fiscalYearId: number) {
    return this._http.delete<any>(`data/${fiscalYearId}`);
  }
  finalize(fiscalYearId: number) {
    return this._http.get<any>(`data/error/finalize/${fiscalYearId}`);
  }
  finalizeFormsChecked(formIds: any, fiscalYearId: number) {
    return this._http.get<any>(`data/error/finalizeFormsChecked/${formIds}/${fiscalYearId}`);
  }

  // Forms End points
  getFormsAssessmentMeta() {
    return this._http.get<any>(`forms/assessment/meta`);
  }
  getFormsAssessmentList(fiscalYearId: number, formType: string, filter: Filter) {
    return this._http.post<any>(
      `forms/assessment/list/search/${fiscalYearId}/${formType}`, filter
    );
  }
  getFormsWaiverList(fiscalYearId: number, formType: string, filter: Filter) {
    return this._http.post<any>(
      `forms/waiver/list/search/${fiscalYearId}/${formType}`, filter
    );
  }
  getAllForms(fiscalYearId: number, formType: string, filter: Filter) {
    return this._http.post<any>(
      `forms/demographic/list/search/${fiscalYearId}/${formType}`, filter
    );
  }
  getFormsAssessmentListWithYearIdFormId(fiscalYearId: number, formId: number, filter: Filter) {
    return this._http.post<any>(
      `forms/assessment/list/${fiscalYearId}/${formId}`, filter
    );
  }
  getFormsWaiverListWithYearIdFormId(fiscalYearId: number, formId: number, filter: Filter) {
    return this._http.post<any>(
      `forms/waiver/list/${fiscalYearId}/${formId}`, filter
    );
  }
  saveAssessmentForm(formId: number, formType: string, form: any) {
    return this._http.post<any>(
      `forms/assessment/save/${formId}/${formType}`, form
    );
  }
  getAssessmentErrorList(fiscalYearId: number, filter: Filter) {
    return this._http.post<any>(
      `forms/errors/list/search/${fiscalYearId}`, filter
    );
  }
  getAssessmentErrorWithFormId(fiscalYearId: number, formId: number, filter: Filter) {
    return this._http.post<any>(
      `forms/errors/list/${fiscalYearId}/${formId}`, filter
    );
  }
  uploadFormAssessFile(event: any, fiscalYearId: number, uploadType: string) {
    const fileList: FileList = event.target.form.myFile.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      return this._http.post<any>(
        `forms/upload/${fiscalYearId}/${uploadType}`, formData
      );
    }
  }
  saveAssessmentErrorForm(formId: number, form: any) {
    return this._http.post<any>(
      `forms/assessment/error/save/${formId}`, form
    );
  }
  finalizeAssessments(fiscalYearId: number) {
    return this._http.get<boolean>(`forms/errors/finalize/${fiscalYearId}`);
  }
  finalizeChecked(formIds:any, fiscalYearId: number) {
    return this._http.get<boolean>(`forms/errors/finalizeChecked/${formIds}/${fiscalYearId}`);
  }
  saveFormType(formId: number, formType: string, fiscalYearId: number) {
    const whatever = '0'
    return this._http.post<any>(`forms/demographic/save/${formId}/${formType}/${fiscalYearId}`,whatever);
  }
  deleteTempAssessment(id: number) {
    return this._http.delete<any>(`forms/errors/list/${id}`);
  }
  deleteAssessment(id: number) {
    return this._http.delete<any>(`forms/assessment/list/${id}`);
  }
  /**
   *   School End points
   */
  getSchoolList(filter: Filter) {
    return this._http.post<SchoolSearchResult[]>(
      `school/list/search`, filter
    );
  }
  getSchoolWithId(id: number) {
    return this._http.get<any>(`school/${id}`);
  }
  saveSchool(school: any) {
    return this._http.post<any>(
      `school/${school.schoolId}`, school
    );
  }

  deleteSchool(id: number) {
    return this._http.delete<any>(`school/${id}`);
  }

  /**
   *    Reports End points
   */
  getComparisonOptions() {
    return this._http.get<any>(`reports/comparison/getColumns`);
  }

  summaryReport(id: number): Promise<any> {
    return this._http.get<any>(
      `reports/summary/${id}`
    ).toPromise();
  }

  stateReport(id: number): Promise<any> {
    return this._http.get<any>(
      `reports/state/${id}`
    ).toPromise();
  }

  schoolReport(fId: number, cId: number, dId: number): Promise<any> {
    return this._http.get<any>(
      `reports/school/${fId}/${cId}/${dId}`
    ).toPromise();
  }
  comparisonReport(fiscalId: number, horizontal: string, vertical: string, filterBy: string, filters: string): Promise<any> {
    return this._http.get<any>(
      `reports/comparison/${fiscalId}/${horizontal}/${vertical}/${filterBy}?filters=${filters}`
    ).toPromise();
  }

  /**
   *    List Serve End points
   */
  sendEmail(email: any) {
    return this._http.post<any>(
      `listServe/postEmail`, email
    ).toPromise();
  }
}
