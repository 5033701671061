import { Component, OnInit } from '@angular/core';
import { ApiCommunicationService, AuthenticationService } from 'src/app/_shared/services/_services';
import { Router } from '@angular/router';
import { County, District } from 'src/app/_shared/models/_models';

class SignupForm {
  lname: string;
  fname: string;
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  phone: string;
  title: string;
  districtID: number;
  countyID: number;
}

class NameEmail {
  name: string;
  email: string;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  // panel checks
  showSignupForm: boolean;
  districtUsers: NameEmail[];
  error: string;
  // selected drop downs
  selectedCounty: number;
  selectedDistrict: number;
  // meta props
  counties: County[] = [];
  districtsFull: District[] = [];
  districts: District[] = [];
  // new signup form
  inputForm = new SignupForm();
  constructor(private api: ApiCommunicationService, private authenticationService: AuthenticationService, private _router: Router) { }

  ngOnInit() {
    this.panelReset();
    this.api.getSignupMeta().subscribe(response => {
      const { counties, districts } = response;
      this.counties = counties;
      this.districtsFull = districts;
    });
  }

  countySelected() {
    if (this.selectedCounty) {
      this.panelReset();
      this.districts = this.districtsFull.filter(dist => {
        return dist.countyID.toString() === this.selectedCounty.toString();
      });
    }
  }

  districtSelected() {
    this.panelReset();
    this.api.checkDistrict(this.selectedDistrict).subscribe((response: NameEmail[]) => {
      if (response.length > 0) {
        this.districtUsers = response;
      } else {
        this.showSignupForm = true;
      }
    });
  }

  panelReset() {
    this.districtUsers = [];
    this.showSignupForm = false;
  }

  submit() {
    this.inputForm.districtID = this.selectedDistrict;
    this.inputForm.countyID = this.selectedCounty;
    this.api.saveSignupNew(this.inputForm).subscribe(_ => {
      window.alert('Thank you, your account has been created. A link to create your password has been sent to your inbox using your provided email address. If you cannot locate the create password email in your inbox after 10-15 minutes, please check your SPAM/Junk Email folder(s) and contact the SCOHR Help Desk as soon as possible if you need any assistance.');
      this._router.navigate(['home/overview']);
    }, ex => window.alert(ex.error));
  }

  //submit() {
  //  if (this.inputForm.password != null && this.inputForm.password !== '' && this.inputForm.password.length > 4) {
  //    if (this.inputForm.password === this.inputForm.confirmPassword) {
  //      this.inputForm.districtID = this.selectedDistrict;
  //      this.inputForm.countyID = this.selectedCounty;
  //      this.api.saveSignup(this.inputForm).subscribe(response => {
  //          const { token, user } = response;
  //          this.authenticationService
  //            .saveToken(
  //              {
  //                username: user.username,
  //                fullName: user.fullName,
  //                email: user.email,
  //              },
  //              token,
  //            )
  //            .then(() => {
  //               this._router.navigate(['admin/authrequest'], { queryParams: { user: 'new' } });
  //              //this._router.navigate(['admin/main']);
  //            });
  //        },
  //        err => {
  //          this.error = err.error;
  //        });
  //    } else {
  //      window.alert('Password does not match');
  //    }
  //  } else {
  //    window.alert('Password must have more than 4 characters');
  //  }
  //}
}
