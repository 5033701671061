import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/_shared/services/authentication.service';
import { Router } from '@angular/router';
import { isNullOrEmpty } from 'src/app/_shared/services/shared.service';

export class User {
  Username: string;
  Password: string;

  constructor() {
    this.Username = '';
    this.Password = '';

  }
}
class UserResponse {
  userId: number;
  fullName: string;
  email: string;
  username: string;
  typeId: number;
  isLocked: boolean;
  canRequestAuth: boolean;
}
class LoginResponse {
  token: string;
  user: UserResponse;
  message: string;
  needsReset: boolean;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  user: User = new User();
  error: string;
  loginLoading = false;

  @ViewChild('yourForgotModal', { static: true }) yourForgotModal: any;

  constructor(
    private authenticationService: AuthenticationService,
    private _router: Router
  ) { }


  ngOnInit() { }


  forgotPassword() {
// Not used anymore
    if (isNullOrEmpty(this.user.Username)) {
      //this.error = 'Username is required to find your information';
      this.error = 'Please type in the email address associated with your SCOHR account and click "Forgot Password" link. \n';
    } else {
      this.authenticationService.requestPasswordReset(this.user.Username)
        .then(_ => window.alert('Your username and password will be emailed to the email account provided within 15 minutes. Please check your Spam/Junk mail.'))
        .catch(_ => window.alert('Username not found. Please contact support if you have forgotten your username'));
    }
  }
  onSubmit() {
    this.loginLoading = true;
    this.authenticationService.login(this.user).subscribe(response => {
      const lr = response as LoginResponse;
      if (lr != null && lr.token) {
        this.authenticationService
          .saveToken(
            {
              userId:lr.user.userId,
              username: lr.user.username,
              fullName: lr.user.fullName,
              typeId: lr.user.typeId,
              email: lr.user.email,
            },
            lr.token,
          )
          .then(() => {
            if (lr.user.isLocked && lr.user.canRequestAuth) {
              this._router.navigate(['admin/authrequest']);
            } else if (lr.user.isLocked) {
              this.loginLoading = false;
              this.error = 'The fiscal year has ended. Your account needs to be re-activated by a District user.';
            } else {
              this._router.navigate(['admin/main']);
            }
          });
      } else if (lr != null && lr.needsReset) {
        alert('This account requires an updated password. Please use the Forgot Password to request a password reset link. If you need help logging in please email support at SCOHR@SJCOE.Net. Thank you.');
        this.loginLoading = false;
      } else {
        this.loginLoading = false;
        this.error = 'Unknown Error';
      }
    },
      ex => {
        this.loginLoading = false;
        this.error = ex.error;
      },
    );
  }

  forgotPassword2() {
    this.yourForgotModal.showModal();
  }

}
