import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './public/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard, AuthenticationService, ScohrHttpInterceptor, FiscalAuthGuard } from './_shared/services/_services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from './_shared/components/shared-components.module';
import { PublicModule } from './public/public.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    SharedComponentsModule,
    PublicModule
  ],
  providers: [
    AuthGuard,
    FiscalAuthGuard,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScohrHttpInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
