import { Component, ElementRef, EventEmitter,  Output, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_shared/services/_services';
import { SCOHRValidators } from 'src/app/_shared/components/validators/scohr-validators';
import { ValidatorConstants } from 'src/app/_shared/components/validators/validator.constants';
import { isNullOrEmpty } from '../../../_shared/services/shared.service';

export class User {
  eMailAddress: string;

  constructor() {
    this.eMailAddress = '';
  }
}
@Component({
  selector: 'app-forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('open', style({ height: '*' })),
      transition('open <=> closed', animate('500ms')),
    ])
  ]
})
export class ForgotModalComponent  {
  user: User = new User();
  formExpanded: boolean = true;
  @Output() closing: EventEmitter<any> = new EventEmitter();
  @ViewChild('forgotPasswordModal', { static: true }) forgotPasswordModal!: ElementRef;

  constructor(
    private _modalService: NgbModal,
    private _authService: AuthenticationService,
  ) {  }

  showModal() {
    this._modalService.open(this.forgotPasswordModal, { size: 'lg', backdrop: 'static', centered: true });
  }

  closeModals() {
    this.closing.emit();
    this._modalService.dismissAll();
  }

  async processForgot() {
    //window.alert(this.user.eMailAddress);

    if (isNullOrEmpty(this.user.eMailAddress)) {
      window.alert('Please type in the email address associated with your SCOHR account and click Submit. \n');

    }
    if(this.user.eMailAddress.length > 0) {
      this._authService.requestPasswordReset(this.user.eMailAddress)
        .then(_ => window.confirm('An email will be sent to the associated account/s provided within 15 minutes. Please check your Spam/Junk mail.'))
        .catch(_ =>window.confirm('The email address that was entered is not associated with an account in the SCOHR system. Please try a different email.'))
             this.closeModals();
        };
    }   

  }








