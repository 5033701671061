import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from 'src/app/public/login/login.component';
import { BehaviorSubject } from 'rxjs';
import { School } from '../models/_models';

@Injectable()
export class AuthenticationService extends SharedService {

  public readonly scohrUserToken = new BehaviorSubject({ token: this.getToken(), user: this.getUser() });

  constructor(http: HttpClient, router: Router) {
    super(http, router);
  }

  login(user: User) {
    return this._http.post<any>(`users/login`, user);
  }

  public getToken(): string {
    const scohrUserToken = JSON.parse(sessionStorage.getItem('scohrUser'));
    return scohrUserToken != null ? scohrUserToken.token : null;
  }

  public getUser(): any {
    const scohrUserToken = JSON.parse(sessionStorage.getItem('scohrUser'));
    return scohrUserToken != null ? scohrUserToken.user : null;
  }

  public saveUser(user: any) {
    const scohrUserToken = JSON.parse(sessionStorage.getItem('scohrUser'));
    scohrUserToken.user = user;
    sessionStorage.setItem('scohrUser', JSON.stringify(scohrUserToken));
  }

  async saveToken(user: any, token: string) {
    const newUserToken = {
      user: {
        userId: user.userId,
        username: user.username,
        role: user.role,
        typeId: user.typeId,
        email: user.email
      },
      token: token
    };
    sessionStorage.clear();
    sessionStorage.setItem('scohrUser', JSON.stringify(newUserToken));
    this.scohrUserToken.next(newUserToken);
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    sessionStorage.setItem('scohrUser', null);
    this.scohrUserToken.next({ token: null, user: null });
    this._router.navigate(['home/overview']);
  }

  requestPasswordReset(username: string) {
    return this._http.get(`users/requestForgotPassword?username=${username}`).toPromise();
  }

  validPasswordResetRequest(key: string) {
    return this._http.get(`users/validateRequestForgotPassword?guid=${key}`).toPromise();
  }

  passwordReset(key: string, user: User) {
    return this._http.post(`users/resetPassword?guid=${key}`, user).toPromise();
  }
}
