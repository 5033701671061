import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  protected helper = new JwtHelperService();
  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate() {
    if (this.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }

  canLoad() {
    if (this.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }

  isFiscalYearLocked(): boolean {
    const { role }: { role: string[] } = this.helper.decodeToken(this.authService.getToken());
    return role != null && role.includes('IsFiscalLocked');
  }

  isAuthorized(required: string): boolean {
    const { role }: { role: string[] } = this.helper.decodeToken(this.authService.getToken());
    return role != null && role.includes(required);
  }

  isAuthenticated(): boolean {
    const token: any = this.authService.getToken();
    return !(token == null || this.helper.isTokenExpired(token));
  }
}

@Injectable()
export class FiscalAuthGuard implements CanActivate, CanLoad {
  protected helper = new JwtHelperService();
  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate() {
    if (this.isFiscalYearLocked()) {
      this.router.navigate(['admin/authrequest']);
      return false;
    }
    return true;
  }

  canLoad() {
    if (this.isFiscalYearLocked()) {
      this.router.navigate(['admin/authrequest']);
      return false;
    }
    return true;
  }

  isFiscalYearLocked(): boolean {
    const { role }: { role: string[] } = this.helper.decodeToken(this.authService.getToken());
    return role != null && role.includes('IsFiscalLocked');
  }
}
